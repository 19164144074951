<template>
  <div>
    <div class="card" :class="$style.container">
      <div class="text-dark font-size-32 mb-3">Olvidé mi Contraseña</div>
      <a-form-model
        v-show="!sendEmail"
        class="mb-4"
        ref="rule-Form"
        :model="form"
        :rules="rules"
        layout="vertical"
        :wrapper-col="{ span: 24}"
      >
        <a-form-model-item ref="email"  prop="email" >
          <a-input v-model.trim="form.email" size="large" />
        </a-form-model-item>
        <div class="row justify-content-center">
          <a-button
            type="primary"
            htmlType="submit"
            size="large"
            class="text-center w-100"
            :loading="loading"
            @click="onSubmit"
          >
            <strong>Olvidé mi Contraseña</strong>
          </a-button>
        </div>
      </a-form-model>

      <a-alert
        class="my-4"
        v-show="sendEmail"
        :message="messageResponse.message"
        :description="messageResponse.description"
        :type="messageResponse.type"
        show-icon
      />
      <a-button
        v-show="sendEmail"
        type="default"
        htmlType="submit"
        size="large"
        class="text-center w-100 mb-4"
        :loading="loading"
        @click="reset"
      >
        <strong>Volver a intentar</strong>
      </a-button>
      <router-link to="/auth/login" class="kit__utils__link font-size-16">
        <i class="fe fe-arrow-left mr-1 align-middle" />
        Acceder
      </router-link>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import apiClient from '@/services/axios'
export default {
  name: 'CuiForgotPassword',
  data() {
    return {
      loading: false,
      sendEmail: false,
      messageResponse: {
        message: '',
        description: '',
        type: 'info',
      },
      form: {
        email: '',
      },
      rules: {
        email: [
          { required: true, message: 'El campo Correo Electrónico es obligatorio.', trigger: 'blur' },
          { type: 'email', message: 'El campo Correo Electrónico no es válido.', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    anErrorHasOcurred() {
      this.sendEmail = true
      this.messageResponse.message = '¡Ups!'
      this.messageResponse.description = 'El email no coincide con nuestros registros'
      this.messageResponse.type = 'warning'
    },
    reset() {
      this.loading = false
      this.sendEmail = false
      this.messageResponse = {
        message: '',
        description: '',
        type: 'info',
      }
      this.form = {
        email: '',
      }
    },
    onSubmit() {
      this.loading = true
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          apiClient.post('/forgot-password', { ...this.form }).then(res => {
            this.loading = false
            console.log(res.data)
            if (res.data.status === 'passwords.user') {
              this.anErrorHasOcurred()
              return
            }
            this.sendEmail = true
            this.messageResponse.message = '¡Listo!'
            this.messageResponse.description = ' Se ha enviado un correo electrónico para el reestablecimiento de su contraseña.'
            this.messageResponse.type = 'info'
          }).catch(() => {
            this.loading = false
            this.sendEmail = true
            this.messageResponse.message = '¡Ups!'
            this.messageResponse.description = 'Ha ocurrido un error .'
            this.messageResponse.type = 'error'
          })
        } else {
          return false
        }
      })
    },
  },
  mounted() {
    // console.log(this.form.getForm())
  },
}
</script>
<style lang="scss" module>
  @import "@/@airui/system/Auth/style.module.scss";
</style>
